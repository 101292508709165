import withMemo from "@eabgaryan/with-memo";
import type { LeadPack } from "@gs/core/domain/Lead/createLeadApi";
import { createLeadApi } from "@gs/core/domain/Lead/createLeadApi";
import { getBaseAxios } from "services/axios";
import { leadStore } from "./leadStore";
import type { Lead, LeadId } from "@gs/core/domain/Lead/Lead";
import { leadMarkersStore } from "./leadMarkersStore";
import type { LeadMarkers } from "@gs/core/domain/LeadMarkers/LeadMarkers";
import stableStringify from "fast-json-stable-stringify";
import { massActionStore } from "domain/massAction/massActionStore";
import { dataBus } from "services/dataBus";


const storeAllLeadsData = (data: Array<LeadPack>) => {
  leadStore.setState((prev) => ({
    ...prev,
    ...data.reduce<Record<LeadId, Lead>>((acc, dataItem) => {
      acc[dataItem.lead.uuid] = dataItem.lead;
      return acc;
    }, {}),
  }));
  leadMarkersStore.setState((prev) => ({
    ...prev,
    ...data.reduce<Record<LeadId, LeadMarkers>>((acc, dataItem) => {
      acc[dataItem.lead.uuid] = dataItem.markers;
      return acc;
    }, {}),
  }));
};

export const leadApi = (() => {
  const leadsAxios = getBaseAxios("contactV2");
  const coreLeadApi = createLeadApi(leadsAxios);

  const getLeads: typeof coreLeadApi.getLeads = async (...args) => {
    const result = await coreLeadApi.getLeads(...args);

    storeAllLeadsData(result.data.data);

    return result;
  };

  const getLeadsByIds: typeof coreLeadApi.getLeadsByIds = async (...args) => {
    const result = await coreLeadApi.getLeadsByIds(...args);

    storeAllLeadsData(result.data);

    return result;
  };

  const getLeadsMemoized = withMemo(
    getLeads,
    {
      ttl: 10_000,
      getKey: stableStringify,
    },
  );

  const getLead: typeof coreLeadApi.getLead = async (...args) => {
    const result = await coreLeadApi.getLead(...args);
    storeAllLeadsData([result.data]);
    return result;
  };

  const updateLead: typeof coreLeadApi.updateLead = async (...args) => {
    const result = await coreLeadApi.updateLead(...args);
    storeAllLeadsData([result.data]);
    return result;
  };

  const callExportedWebhook: typeof coreLeadApi.callExportedWebhook = async (...args) => {
    const response = await coreLeadApi.callExportedWebhook(...args);

    getLeadsMemoized.invalidateCache();

    return response;
  };

  const getFilterSuggestions = coreLeadApi.getFilterSuggestions;

  const getLeadMetrics = coreLeadApi.getLeadMetrics;

  const callLeadMassAction: typeof coreLeadApi.callLeadMassAction = async (...args) => {
    const result = await coreLeadApi.callLeadMassAction(...args);
    if (result.data.mass_action) {
      massActionStore.setState({
        [result.data.mass_action.uuid]: result.data.mass_action,
      });
      dataBus.emit("massAction:create", { massActionIds: [result.data.mass_action.uuid] });
    } else {
      storeAllLeadsData(result.data.data);
    }
    dataBus.emit("massAction:applied", {
      type: args[0].type,
      filter: args[0].filter,
    });
    return result;
  };

  return {
    getSearchCounts: coreLeadApi.getSearchCounts,
    updateLead,
    getLeads,
    getLeadsMemoized,
    getLead,
    getLeadsByIds,
    callExportedWebhook,
    getFilterSuggestions,
    getLeadMetrics,
    callLeadMassAction,
  };
})();
