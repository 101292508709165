import type { AxiosInstance } from "axios";
import type { ApiListResponse, Mixed, OrderType } from "../../commonTypes";
import type { AxiosRequestConfig } from "axios";
import type { UserId, User, UserGaTrackingId } from "./User";
import type { WhitelabelId } from "../Whitelabel/Whitelabel";
import type { UserTeam } from "../UserTeam/UserTeam";

export const createUserApi = (axios: AxiosInstance) => {
  const getUsers = (
    params: {
      filter?: {
        query?: string
        id?: Mixed<UserId>
        firstName?: Mixed<string>
        lastName?: Mixed<string>
        email?: Mixed<string>
        withDeleted?: boolean
        whitelabelId?: Mixed<WhitelabelId>
        gaTrackingId?: Mixed<UserGaTrackingId>
        emailStatus?: Mixed<User["email_status"]>
      }
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<ApiListResponse<User>>(
      "/api/users",
      {
        ...config,
        params: {
          order_field: params.orderField,
          order_type: params.orderType,
          limit: params.limit,
          offset: params.offset,
          filter: {
            q: params.filter?.query,
            id: params.filter?.id,
            first_name: params.filter?.firstName,
            last_name: params.filter?.lastName,
            email: params.filter?.email,
            deleted: params.filter?.withDeleted,
            whitelabel_uuid: params.filter?.whitelabelId,
            ga_tracking_id: params.filter?.gaTrackingId,
            email_status: params.filter?.emailStatus,
          },
        },
      },
    );
  };

  const updateUser = (
    params: {fields: Partial<Pick<User, "source" | "industry" | "last_name" | "first_name">>}, // TODO: add all editable fields
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<User>("/api/users", params.fields, config);
  };

  const confirmEmail = (
    params: {email: string; code: string},
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<User>(`/api/users/${params.email}/confirm-email/${params.code}`, {}, config);
  };

  const resendConfirmationEmail = (
    params?: unknown,
    config?: AxiosRequestConfig,
  ) => {
    return axios.post("/api/users/resend-confirmation-email", {}, config);
  };

  const getUsersByIds = (params: { ids: UserId[] }, config?: AxiosRequestConfig) => {
    return axios.post<User[]>("/api/users/list-by-ids", { ids: params.ids }, config);
  };

  const getUserAcceptedInvite = (params: {id: UserId}, config?: AxiosRequestConfig) => {
    return axios.get<UserTeam>(`/api/teams/users/${params.id}/get-accepted-invitation`, config);
  };

  return {
    getUsers,
    updateUser,
    confirmEmail,
    getUsersByIds,
    resendConfirmationEmail,

    getUserAcceptedInvite,
  };
};
