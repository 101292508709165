import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { SenderProfileId } from "../SenderProfile/SenderProfile";
import type { Lead, LeadId } from "./Lead";
import type { ApiListResponse, IsoDate, Mixed, QueuedJobResponse, OrderType } from "../../commonTypes";
import type { LeadMarkers } from "../LeadMarkers/LeadMarkers";
import type { FlowId } from "../Flow/Flow";
import type { WebhookId } from "../Webhook/Webhook";
import type { SenderProfileMetrics } from "../SenderProfile/SenderProfile";
import type { MassActionTypeMap } from "../MassAction/MassAction";
import type { LeadMassActionType } from "../MassAction/massActionType";
import type { MassActionResponse } from "../MassAction/createMassActionApi";
import type { UserId } from "../User/User";
import type { ListId } from "../List/List";
import type { DataSourceId } from "../DataSource/DataSource";
import type { PipelineStageId } from "../PipelineStage/PipelineStage";
import type { CompanyId } from "../Company/Company";
import type { TagId } from "../Tag/Tag";


export type LeadPack = {
  // TODO:
  // flows: Array<LeadFlow>
  // custom_fields
  lead: Lead
  markers: LeadMarkers
}

export type LeadFilter = {
  query?: string
  id?: LeadId | LeadId[]
  userId?: Mixed<UserId>
  senderProfileId?: Mixed<SenderProfileId>
  listId?: Mixed<ListId>
  dataSourceId?: Mixed<DataSourceId>
  pipelineStageId?: Mixed<PipelineStageId>
  companyId?: Mixed<CompanyId>
  name?: Mixed<Lead["name"]>
  companyName?: Mixed<Lead["company_name"]>
  lnId?: Mixed<Lead["ln_id"]>
  snId?: Mixed<Lead["sn_id"]>
  linkedin?: Mixed<Lead["linkedin"]>
  lists?: Mixed<ListId>
  workEmail?: Mixed<Lead["work_email"]>
  personalEmail?: Mixed<Lead["personal_email"]>
  workPhoneNumber?: Mixed<Lead["work_phone_number"]>
  personalPhoneNumber?: Mixed<Lead["personal_phone_number"]>
  tags?: Mixed<TagId>
  duplicate_data_sources?: Mixed<DataSourceId>
  status?: Mixed<Lead["status"]>
  linkedinStatus?: Mixed<Lead["linkedin_status"]>
  emailStatus?: Mixed<Lead["email_status"]>
  // public mixed $deleted = false, // doesn't work with elastic
}

export type LeadMassActionFilter = {
  all: boolean
  ids: LeadId[]
  elasticQuery?: unknown
  includeCoworkers?: boolean
  filter?: LeadFilter
}

export const leadFilterToServerFormat = (leadFilter?: LeadFilter) => leadFilter && ({
  q: leadFilter.query,
  _id: leadFilter.id,
  user_id: leadFilter.userId,
  sender_profile_uuid: leadFilter.senderProfileId,
  list_uuid: leadFilter.listId,
  data_source_uuid: leadFilter.dataSourceId,
  pipeline_stage_uuid: leadFilter.pipelineStageId,
  company_uuid: leadFilter.companyId,
  name: leadFilter.name,
  company_name: leadFilter.companyName,
  ln_id: leadFilter.lnId,
  sn_id: leadFilter.snId,
  linkedin: leadFilter.linkedin,
  lists: leadFilter.lists,
  work_email: leadFilter.workEmail,
  personal_email: leadFilter.personalEmail,
  work_phone_number: leadFilter.workPhoneNumber,
  personal_phone_number: leadFilter.personalPhoneNumber,
  tags: leadFilter.tags,
  duplicate_data_sources: leadFilter.duplicate_data_sources,
  status: leadFilter.status,
  email_status: leadFilter.emailStatus,
});

export const leadMassFilterToServerFormat = (massFilter?: LeadMassActionFilter) => massFilter && ({
  all: massFilter.all,
  ids: massFilter.ids,
  elasticQuery: massFilter.elasticQuery,
  includeCoworkers: massFilter.includeCoworkers,
  leadFilter: leadFilterToServerFormat(massFilter.filter),
});

export type LeadMassActionServerFilter = NonNullable<ReturnType<typeof leadMassFilterToServerFormat>>

export const createLeadApi = (axios: AxiosInstance) => {
  const getSearchCounts = (params: Omit<LeadMassActionFilter, "includeCoworkers">, config?: AxiosRequestConfig) => {
    return axios.post<{leads_count: number; coworkers_count: number}>(
      "/api/leads/search-counts",
      { filter: leadMassFilterToServerFormat(params) },
      config,
    );
  };
  const getLeads = (
    params: {
      filter?: LeadFilter & {
        leadElasticQuery?: unknown
      }
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
      nestedSortFilter?: {
        "markers.sender_profile_uuid"?: null | SenderProfileId
      }
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<ApiListResponse<LeadPack>>(
      "/api/leads/list",
      {
        order_field: params.orderField,
        order_type: params.orderType,
        limit: params.limit,
        offset: params.offset,
        nested_sort_filter: params.nestedSortFilter,
        filter: {
          elasticQuery: params.filter?.leadElasticQuery,
          leadFilter: leadFilterToServerFormat(params.filter),
        },
      },
      config,
    );
  };

  const getLead = (params: { id: LeadId }, config?: AxiosRequestConfig) => {
    return axios.get<LeadPack>(`/api/leads/${params.id}`, config);
  };

  const createLead = (params: {fields: Omit<Lead, "uuid"> }, config?: AxiosRequestConfig) => {
    return axios.post<LeadPack>("/api/leads/", params.fields, config);
  };

  const updateLead = (params: { id: LeadId; newFields: Partial<Omit<Lead, "uuid">> }, config?: AxiosRequestConfig) => {
    return axios.put<LeadPack>(`/api/leads/${params.id}`, params.newFields, config);
  };

  // this method exists, because `getLeads` works via elastic search and cant find soft deleted leads
  const getLeadsByIds = (ids: LeadId[], config?: AxiosRequestConfig) => {
    return axios.post<LeadPack[]>("/api/leads/list-by-uuids", { uuids: ids }, config);
  };

  const callExportedWebhook = async (
    params: {
      webhookId: WebhookId
      filter: LeadMassActionFilter
    },
  ) => {
    return axios.put<QueuedJobResponse<LeadPack>>("/api/leads/call-exported-webhook", {
      webhook_uuid: params.webhookId,
      filter: leadMassFilterToServerFormat(params.filter),
    });
  };

  const getFilterSuggestions = (params: {
    elasticQuery?: unknown
    fieldName: string
    query: string
  }) => {
    return axios.post<Array<{
      count: number
      key: string
      label: string
    }>>(
      "/api/leads/search-suggestions",
      {
        filter: {
          elasticQuery: params.elasticQuery,
        },
        suggest_name: params.fieldName,
        term: params.query,
      },
    );
  };

  const callLeadMassAction = async<TheActionType extends LeadMassActionType> (
    params: {
      type: LeadMassActionType
      payload?: MassActionTypeMap[TheActionType]["payload"]
      filter: LeadMassActionFilter
    }) => {
    return axios.put<MassActionResponse<LeadPack>>("/api/leads/mass-action", {
      type: params.type,
      payload: params.payload,
      filter: leadMassFilterToServerFormat(params.filter),
    });
  };

  const getLeadMetrics = <Metrics extends Array<keyof SenderProfileMetrics>>(
    params: {
      metrics: Metrics
      senderProfileIds?: Array<SenderProfileId>
      flowIds?: Array<FlowId | "is_null">
      from?: IsoDate
      to?: IsoDate
      filter?: {
        leadElasticQuery?: unknown
      }
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<{
      total: {[key in Metrics[number]]: number}
      metrics: Record<SenderProfileId, {[key in Metrics[number]]: number}>
    } & {
      [key in SenderProfileId]: {[key in Metrics[number]]: number}
    }>(
      "/api/leads/metrics",
      {
        period_from: params.from,
        period_to: params.to,
        sender_profiles: params.senderProfileIds,
        flows: params.flowIds,
        metrics: params.metrics,
        filter: {
          elasticQuery: params.filter?.leadElasticQuery,
        },
      },
      config,
    );
  };

  return {
    getSearchCounts,
    getLeads,
    getLeadsByIds,
    getLead,
    updateLead,
    createLead,
    callExportedWebhook,
    getFilterSuggestions,
    getLeadMetrics,
    callLeadMassAction,
  };
};
